import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { Image } from '../../Image';
import { FaChevronRight } from 'react-icons/fa';
import './ProductListItem.scss'
import { decodeEntities } from '../../../utils/helpers';

export default class ProductListItem extends React.Component {
  render () {
    const { siteMetadata, data, pathPrefix, showAuthor } = this.props;
    const { excerpt, title, path, featured_image_url, author, date, acf } = data
    return (
      <div className="post-list-item">
        <div>
          <div className="image"><GatsbyLink to={path} label={title}><Image src={featured_image_url} className="background" /></GatsbyLink></div>
          <div className="inner">
            <h4 className="title"><GatsbyLink to={path}>{decodeEntities(title)}</GatsbyLink></h4>
            {excerpt && <RenderContent content={`${excerpt}`}/>}
            {acf.productPrice && <h4>{acf.productPrice}</h4>}
            {path && <GatsbyLink to={path} label={title} className="post-link"><FaChevronRight/></GatsbyLink>}
          </div>
        </div>
      </div>
    )
  }
}
