import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import './ProductListNavigation.scss'

export const ProductListNavigation = (props) => {
    const { pathPrefix, categories, posts, pageContext, postSlug } = props;
    const thisCategoryId = pageContext.category_id;
    const thisParentCategoryId = pageContext.parent_category ? pageContext.parent_category : 0;
    const listChildrentOfThis = pageContext.parent_category === 0 ? thisCategoryId : thisParentCategoryId;

    return (
      <section className="post-list-navigation">
        <div className="inner">
          <ul>
            <li>
              <GatsbyLink to={postSlug} decode={true} activeClassName="active">{`All`}</GatsbyLink>
            </li>
            {categories && categories.map(({node: category} , index) =>
              <li key={index}><GatsbyLink to={`${category.path}`} activeClassName="active" decode={true}>{category.name}</GatsbyLink></li>
            )}
          </ul>
        </div>
      </section>
    )
}
